import React from 'react';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import SurveyIframe from 'sections/ScreverOcxProgram/SurveyIframe';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import 'sections/ScreverOcxProgram/common.scss';

const seo = {
  title: 'OCX Event | Feedback | Screver Feedback Management Solution',
  metaDesc:
    'Share your thoughts and opinions after the Screver OCX event! Let us know what you loved, what we can improve on in the future, and any questions or feedback.',
  opengraphUrl: 'https://screver.com/events/screver-ocx-program/feedback/',
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg',
};

const FeedbackPage = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program">
        <HeaderCommon title="Feedback" />

        <main>
          <div className="ocx-prog-wrap">
            <SurveyIframe
              src={
                // 'https://app.screver.com/survey?token=44b07f9d-c8c1-4d9c-9792-8f810cca33da'
                'https://go.screver.com/capptoo/scever-event-feedback'
              }
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default FeedbackPage;
